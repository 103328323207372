// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VUSrksMX2flKO2d2LRNh.f_eloDiC4KqJ3IGDGu1_{height:250px}", "",{"version":3,"sources":["webpack://./../next-react/src/components/atoms/PatientSections/components/ObservationsSection/styles.scss"],"names":[],"mappings":"AAEE,2CACE,YAAA","sourcesContent":["$theme: next;\n.ObservationsSection {\n  &.-compact {\n    height: 250px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ObservationsSection": "VUSrksMX2flKO2d2LRNh",
	"-compact": "f_eloDiC4KqJ3IGDGu1_"
};
export default ___CSS_LOADER_EXPORT___;
