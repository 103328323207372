// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pFgHsI_Dne0BT9NjHGpp{position:relative;height:400px;display:flex}.mM6v0dCbdmcrgXWOEYDm{flex-grow:1;min-width:300px;position:relative}.QIu9tpH8rRp92vTNyBQu{position:absolute;top:0;right:0;width:240px;background:rgba(255,255,255,.7);padding:0 8px 8px 8px;height:250px}.MQOBok3iEb4VpfgL2Xwi{width:260px}", "",{"version":3,"sources":["webpack://./../next-react/src/components/atoms/PlottableObservation/styles.scss"],"names":[],"mappings":"AAOA,sBACE,iBAAA,CACA,YALW,CAMX,YAAA,CAEA,sBACE,WAAA,CACA,eAAA,CACA,iBAAA,CAEA,sBACE,iBAAA,CACA,KAAA,CACA,OAAA,CACA,WAhBU,CAiBV,+BAAA,CACA,qBAAA,CAEA,YAAA,CAIJ,sBACE,WAAA","sourcesContent":["$theme: next;\n@import \"../../../scss/variables.scss\";\n@import \"../../../scss/mixins/clearfix\";\n\n$chart-size: 400px;\n$subchart-size: 240px;\n\n.PlottableObservation {\n  position: relative;\n  height: $chart-size;\n  display: flex;\n\n  &_chart {\n    flex-grow: 1;\n    min-width: 300px;\n    position: relative;\n\n    &_subchart {\n      position: absolute;\n      top: 0;\n      right: 0;\n      width: $subchart-size;\n      background: rgba(#fff, 0.7);\n      padding: 0 $spacing-sm $spacing-sm $spacing-sm;\n      // HACK make bp chart look nice\n      height: $subchart-size + 10px;\n    }\n  }\n\n  &_metrics {\n    width: 260px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PlottableObservation": "pFgHsI_Dne0BT9NjHGpp",
	"PlottableObservation_chart": "mM6v0dCbdmcrgXWOEYDm",
	"PlottableObservation_chart_subchart": "QIu9tpH8rRp92vTNyBQu",
	"PlottableObservation_metrics": "MQOBok3iEb4VpfgL2Xwi"
};
export default ___CSS_LOADER_EXPORT___;
