// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CqsMYv3XPKpI3lJPdtcr{max-height:100%;overflow-y:auto}.EJqBlitQDZxWs2LvlVac{float:right}", "",{"version":3,"sources":["webpack://./../next-react/src/components/atoms/ObservationsList/styles.scss"],"names":[],"mappings":"AACA,sBACE,eAAA,CACA,eAAA,CAGE,sBACE,WAAA","sourcesContent":["$theme: next;\n.ObservationsList {\n  max-height: 100%;\n  overflow-y: auto;\n\n  &_item {\n    &_timestamp {\n      float: right;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ObservationsList": "CqsMYv3XPKpI3lJPdtcr",
	"ObservationsList_item_timestamp": "EJqBlitQDZxWs2LvlVac"
};
export default ___CSS_LOADER_EXPORT___;
